.input__upload {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &__filebox {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 3rem;
    background-color: $color-shades-white;
    border: 2px dashed $color-shades-darkGray;
    border-radius: 0.25rem;
    overflow: hidden;

    &:hover {
      border: 2px dashed $color-shades-lightBlack;
    }

    &.multi {
      height: 5rem;
    }

    &.filled {
      border: 1px solid $color-shades-darkGray;

      input {
        right: 2.5rem;
      }
    }

    &.drop {
      border: 2px solid $color-hues-green;

      &::before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: transparentize($color-hues-green, 0.8);
        z-index: 99;
        pointer-events: none;
      }
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      z-index: 9;
      cursor: pointer;
    }

    &-filled {
      display: flex;
      flex-direction: row;
      width: 100%;

      &__browse {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0.5rem;
        overflow: hidden;
        gap: 0.5rem;

        span {
          line-height: 1.5rem;
          font-weight: $font-weight-medium;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .filetypes {
          display: flex;
          flex-direction: row;
          width: 100%;
          gap: 0.25rem;
    
          li {
            display: flex;
            width: 25%;
            height: 2rem;
            align-items: center;
            justify-content: center;
            font-weight: $font-weight-medium;
            font-size: 0.875rem;
            color: $color-shades-darkGray;
            border: 1px solid $color-shades-lightGray;
            border-radius: 0.25rem;
    
            &.filled {
              color: $color-shades-black;
              border: 2px solid $color-hues-green;
              background-color: transparentize($color-hues-green, 0.8);
            }
          }
        }
      }

      &__remove {
        padding: 0.5rem;
        min-height: 3rem;
        background-color: $color-shades-white;
        border: 0;
        border-left: 1px solid $color-shades-darkGray;
        cursor: pointer;

        svg {
          pointer-events: none;
          width: 1.5rem;
          height: 1.5rem;
        }

        &:hover {
          background-color: $color-flag-notify;

          svg > path {
            fill: $color-shades-white;
          }
        }
      }
    }
  }
}

.input__listupload {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__filebox {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 3rem;
    background-color: $color-shades-white;
    border: 2px dashed $color-shades-darkGray;
    border-radius: 0.25rem;
    overflow: hidden;

    &:hover {
      border: 2px dashed $color-shades-lightBlack;
    }

    &.drop {
      border: 2px solid $color-hues-green;

      &::before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: transparentize($color-hues-green, 0.8);
        z-index: 99;
        pointer-events: none;
      }
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      z-index: 9;
      cursor: pointer;
    }
  }

  &__filelist {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &__file {
      display: flex;
      flex-direction: row;
      border: 1px solid $color-shades-darkGray;
      border-radius: 0.25rem;
      overflow: hidden;

      &-filename {
        flex: 1;
        padding: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        span {
          line-height: 1.5rem;
          font-weight: $font-weight-medium;
        }
      }

      &-remove {
        padding: 0.5rem;
        min-height: 2.5rem;
        background-color: $color-shades-white;
        border: 0;
        border-left: 1px solid $color-shades-darkGray;
        cursor: pointer;

        svg {
          pointer-events: none;
          width: 1.5rem;
          height: 1.5rem;
        }

        &:hover {
          background-color: $color-flag-notify;

          svg > path {
            fill: $color-shades-white;
          }
        }
      }
    }
  }
}