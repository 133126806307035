.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: $color-hues-green;
  border: 1px solid $color-shades-black;
  font-family: $font-family-primary;
  font-weight: $font-weight-semi-bold;
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: center;
  text-transform: uppercase;
  color: $color-shades-black;
  transition: .2s;
  height: 2.5rem;
  padding: 0.75rem 1.5rem;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0.25rem 0 transparentize($color-shades-black, 0.8);
    background-color: $color-shades-white;
  }

  & + button {
    margin-left: 0.125rem;
  }

  &.button-icon {
    padding: 0.5rem;
    width: 2.5rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &.button-small {
    font-size: 0.875rem;
    line-height: 1rem;
    height: 2rem;
    padding: 0.5rem 1rem;
    background-color: $color-shades-white;
    border: 1px solid $color-shades-darkGray;
    width: 100%;

    svg {
      width: 1rem;
      height: 1rem;
    }

    &:hover {
      background-color: $color-shades-lightGray;
      box-shadow: none;
    }
  }

  &.button-link {
    font-family: $font-family-secondary;
    background-color: transparent;
    border: 0;
    text-decoration: underline;
    text-transform: initial;
    padding: 0;

    &:hover {
      box-shadow: none;
    }
  }

  &.button-danger {
    background-color: $color-flag-notify;
    color: $color-shades-white;

    &:hover {
      background-color: $color-shades-white;
      color: $color-shades-black;
    }
  }

  &.button-neutral {
    background-color: $color-shades-lightGray;

    &:hover {
      background-color: $color-shades-white;
    }
  }
}

.button-close {
  display: inline-flex;
  padding: 0.5rem;
  border: 1px solid $color-shades-darkGray;
  background-color: $color-shades-white;
  border-radius: 50%;
  cursor: pointer;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  &:hover {
    background-color: $color-shades-lightGray;
  }

  &:active {
    background-color: $color-shades-black;

    svg > path {
      fill: $color-shades-white;
    }
  }
}

.button-gpscopy {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 0.125rem;

  .button {
    width: 25%;
  }
}