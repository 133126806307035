@mixin badgeColors {
  color: $color-shades-black;
  font-weight: $font-weight-semi-bold;
  font-size: 1rem;

  &.yellow {
    background-color: $color-hues-yellow;
  }
  &.green {
    background-color: $color-hues-green;
  }
  &.red {
    background-color: $color-flag-notify;
    color: $color-shades-white;
  }
  &.gray {
    background-color: $color-shades-darkGray;
    color: $color-shades-white;
  }
  &.black {
    background-color: $color-shades-black;
    color: $color-shades-white;
  }
}

.badge {
  line-height: 1.2;
  padding: 0.25rem 0.5rem;
  text-align: center;
  border-radius: 0.25rem;
  display: none;

  @include DS {
    display: inline-block;
  }

  @include badgeColors();
}

.badge__small {
  display: inline-block;
  position: relative;
  top: 2px;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;

  @include DS {
    display: none;
  }

  @include badgeColors();
}

.badge-tminus {
  display: block;
  padding: 0.25rem 0;
  width: 100%;
  text-align: center;
  border-radius: 0.25rem;
  border: 1px solid $color-shades-lightGray;
  background-color: $color-shades-white;
  font-weight: $font-weight-semi-bold;
}

.badge-purchase {
  line-height: 1.2;
  padding: 0.25rem 0.5rem;
  text-align: center;
  border-radius: 0.25rem;
  display: none;

  @include DL {
    display: inherit;
    margin-left: 0;
    text-align: left;
  }
  @include badgeColors();
}

.badge__medium-purchase {
  position: relative;
  top: 2px;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  display: inline-block;
  margin-left: 0;

  @include DL {
    display: none;
  }

  @include badgeColors();
}

.badge-users {
  padding: 0 0.5rem;
  margin-left: 0.25rem;
  display: inline-flex;
  align-items: center;
  border-radius: 50%;
  font-family: $font-family-secondary;
  font-weight: $font-weight-regular;
  font-size: 1rem;
  line-height: 1.2;
  color: $color-shades-white !important;
  @include badgeColors();

  svg {
    margin-left: 2px;
    position: initial !important;
    width: 12px !important;
    height: 8px !important;
  }
}

.badge-active {
  width: 100%;
  padding: 0.5rem;

  span {
    display: block;
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;

    &.active {
      background-color: $color-hues-green;
    }
    &.inactive {
      border: 2px solid $color-shades-darkGray;
    }
  }
}

.badge__point {
  display: inline-block;
  position: relative;
  top: 2px;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  @include badgeColors();
}

.table__box-requests.expanded {
  .badge-purchase {
    @include DS {
      display: inherit;
      margin-left: 0px;
      text-align: left;
    }
  }

  .badge__medium-purchase {
    @include DS {
      display: none;
    }
  }
}

.modal__header {
  .badge-purchase {
    display: inherit;
    margin-left: 0.5rem;
  }
  .badge__medium-purchase {
    display: none;
  }
}
