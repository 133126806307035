.card {
  &__modal {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.75rem;
    border: 1px solid $color-shades-lightGray;
    border-radius: 0.25rem;
    gap: 0.25rem;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 1.25rem;
    }

    &-meta {
      display: flex;
      flex-direction: column;
      gap: 0.125rem;
    }

    .boxed-text {
      background-color: $color-shades-black;
      color: $color-shades-white;
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      width: fit-content;
    }

    button {
      background-color: transparent;
      border: none;
      padding: 0;
      cursor: pointer;

      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }

  &__need-small {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0;
    border-bottom: 1px solid $color-shades-lightGray;
    gap: 1rem;

    &:nth-last-child(1) {
      border-bottom: 0;
    }

    &__meta {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      overflow: hidden;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
