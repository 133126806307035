.modal__layout {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparentize($color-shades-black, 0.4);
  z-index: 101;
  @include TL {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-centered {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    @include TL {
      height: auto;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin: auto;
  border-radius: 0.5rem;
  background: $color-shades-white;
  overflow: auto;
  gap: 1rem;
  z-index: 10;
  @include TP {
    overflow: unset;
    padding: 1rem 1.5rem 1.5rem;
    width: 490px;
    max-width: 490px;
    min-height: 640px;
  }
  @include DL {
    width: 600px;
    max-width: 600px;
  }

  &-fluid {
    padding: 1rem 1.5rem 1.5rem;
    height: calc(100vh - 3rem);
    width: calc(100vw - 3rem);
    max-width: unset;
    @include DS {
      height: calc(100vh - 4rem);
      width: calc(100vw - 8rem);
    }
    @include DL {
      width: calc(100vw - 14rem);
      max-width: 100rem;
    }

    &__content {
      flex-direction: row;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid $color-shades-lightGray;

    & > div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    &-flex {
      display: flex;
      @include DS {
        display: block;
      }

      h5 {
        cursor: pointer;
      }
    }
  }

  &__content {
    flex: 1;
    display: flex;
    gap: 2rem;
    min-height: 30rem;

    main {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .modal {
        &__form {
          padding-right: 0;
          border-right: none;
        }

        &__actions {
          flex-direction: row;
        }
      }
    }

    hr {
      margin: 0;
    }

    aside {
      display: flex;
      flex-direction: column;
      width: 22rem;
      gap: 1rem;
      @include DL {
        width: 26rem;
      }
      
      hr {
        width: 100%;
      }
    }
  }

  &__cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
    @include DL {
      flex-wrap: nowrap;
    }

    & > div {
      width: calc(50% - 0.25rem);
      @include DL {
        width: 25%;
      }
    }
  }

  &__view {
    flex: 1;
    position: relative;
    width: 100%;
    max-width: 80rem;
    background-color: $color-shades-white;
    border: 1px solid $color-shades-lightGray;
    border-radius: 0.25rem;
    overflow: hidden;

    &.expanded {
      position: fixed;
      top: 0;
      left: 0;
      margin: 0;
      height: 100vh;
      max-width: unset;
      border: none;
      border-radius: 0;
      z-index: 99;
    }
  }

  &__scroll {
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 0.5rem 0;
  }

  &__form {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &__row {
      flex: 1;
      display: flex;
      flex-direction: row;
      gap: 2rem;
    }

    &__fields {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      &-group {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 1rem;

        &.wrap {
          flex-direction: row;
          flex-wrap: wrap;

          h4 {
            width: 100%;
          }
          .input,
          .select {
            width: calc(50% - 0.5rem);
          }
        }
      }
    }

    .modal__actions {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 0.5rem;

      &-group {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 0.25rem;

        .button {
          width: 33%;
        }
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;

    .select {
      width: 100%;
      select {
        margin: 0;
      }
    }

    &-buttons {
      display: flex;
      flex-direction: row;
      gap: 1rem;

      &.rejected {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &__tab {
    &__group {
      display: flex;
      flex-direction: row;
      width: 100%;
      border-bottom: 2px solid $color-shades-lightGray;
    }

    &__item {
      flex: 1;
      position: relative;
      padding: 0 0.5rem 0.375rem;
      border: none;
      background-color: transparent;
      font-size: 1rem;
      line-height: 1.5;
      font-family: $font-family-primary;
      color: $color-shades-lightBlack;
      text-transform: uppercase;
      cursor: pointer;

      &.active {
        font-weight: $font-weight-semi-bold;
        color: $color-shades-black;

        &::after {
          content: "";
          display: block;
          position: absolute;
          bottom: -0.25rem;
          left: 0;
          width: 100%;
          height: 0.25rem;
          background-color: $color-shades-black;
        }
      }
    }
  }

  &__requests-stats {
    display: flex;
    flex-direction: row;
    border: 1px solid $color-shades-darkGray;
    border-radius: 0.25rem;

    & > div {
      width: 50%;
      padding: 0.75rem 1rem;
      text-align: center;

      &:last-child {
        border-left: 1px solid $color-shades-darkGray;
      }
    }
  }

  &__block {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &.notify {
      align-items: center;
      text-align: center;
      padding: 1rem;

      svg {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}